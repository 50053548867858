<template>
  <el-upload action="#" class="upload-demo" drag :http-request="onUpload">
    <i class="el-icon-upload"></i>
    <div class="el-upload__text">
      将文件拖到此处，或<em>点击上传</em>(请先下载模板)
    </div>
  </el-upload>
</template>

<script>
export default {
  props: {
    request: {
      type: Function,
      require: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    // beforeUpload(file) {
    //   console.log(file, "before upload");
    // },
    onUpload(data) {
      const formData = new FormData();
      formData.append("file", data.file);
      this.request(formData);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-upload-dragger {
  width: 100%;
}
/deep/.el-upload {
  width: 100%;
}
</style>
